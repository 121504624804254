import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-state-list',
  templateUrl: './state-list.component.html',
  styleUrls: ['./state-list.component.css']
})
export class StateListComponent implements OnInit {

  type;
  items;
  error:string='';
  response = false;
  success;
  schools = [];
  plans = [];
  smsplan = {price:0};
  routeType;
  request;
  pageOfItems: Array<any>;


  constructor(private api: ApiService, private route: ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) {
    this.request = {status:true,school_id:'',quantity:'',price:''};

  }
  ngOnInit() {
    this.getItems();
    this.api.isLogged();
  }


  getItems() {
    this.api.post('list', { type: 'state' }).subscribe(
      data => {
        this.items = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  changeQuantity(id) {
  }
  submitItem(form) {
  }
  deleteItem(id) {
    this.response = true;

    this.api.delete('delete',{ id: id, type: 'state' }).subscribe(
      data => {
        this.response = false;
        this.getItems();
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  }

  
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}

