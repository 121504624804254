import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})
export class SchoolListComponent implements OnInit {

  items: any;
  response = true;
  status:string;

  constructor(private api:ApiService, private route:ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) { 
   this.status = this.route.snapshot.params['type'];
  }

  ngOnInit() {
    this.getSchool(this.status);
    this.api.isLogged()
  }

  getSchool(status){
    this.api.post('list', {type:'organizations', status: status } ).subscribe(
      data=>{
        this.response = false;
        this.items = data;
      },
      err =>{
        console.error(err);
      }
    )
  };
  
  statusChange(event, school_id){
    this.api.post('organization/status', {value: event.target.checked, school_id} ).subscribe(
      data=>{
      },
      err =>{
        console.error(err);
      }
    )
  };

  
  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  deleteItem(id) {
    this.response = true;

    this.api.delete('delete',{ id: id, type: 'organizations' }).subscribe(
      data => {
        this.response = false;
        this.getSchool(this.status);
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  }


}
