import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {

  items: any =  [];
  response;
  type;
  private sub: any;
  constructor(private api:ApiService, private route:ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) { 
   // this.type = this.route.snapshot.params['type'];


  }

  ngOnInit() {
    this. getItems();
    this.api.isLogged()

  }

  getItems(){
    this.api.post('list', { type: 'sms-list' }).subscribe(
      data=>{
        this.items = data;
      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  deleteItem(id){
    this.response=true;

    this.api.delete('delete',{ id: id, type: 'sms-plan' }).subscribe(
      data=>{
        this.response=false;
        this.getItems();
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };


  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
