import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-country-add',
  templateUrl: './country-add.component.html',
  styleUrls: ['./country-add.component.css']
})
export class CountryAddComponent implements OnInit {

  id:number;
  items ={};
  item;
  responseSuccess:string='';
  responseError:string='';
  response = false;
  submitted = false;
  registerForm: FormGroup;

  constructor(private api:ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
   }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];

    if(this.id){
      this.getItemById(this.id);

    }
    this.registerForm = this.formBuilder.group({
      title: ['', Validators.required],
      currency: ['', Validators.required],
      iso: ['', Validators.required],
      symbol: ['', Validators.required],
      status: ['1'],
  
    })

  }

  get f() { return this.registerForm.controls; }

  submitItem(){

    if (this.registerForm.invalid) {
      return;
    }
  
    this.response = true;
    this.submitted = true;
    let request = this.registerForm.value;
    request.type = 'country';
    request.id = this.id;
    this.response = true;
  
    this.api.add('add', request).subscribe(
      data=>{
          if(data){
            this.responseSuccess= this.id ? "Item updated successfully" : "New item created successfully";
            if(!this.id){
              this.registerForm.reset();
              this.submitted = false;
            }
          }
        },
        err =>{
          this.response = false;
        },
        ()=>{
          this.response =false;
        }
      )
    }

      
  getItemById(id){
    this.response = true;

    this.api.edit('edit', {id:id, type:"country"}).subscribe(
      data=>{
        this.item = data;
        this.registerForm = this.formBuilder.group({
          title: [this.item.title, Validators.required],
          currency: [this.item.currency, Validators.required],
          iso: [this.item.iso, Validators.required],
          symbol: [this.item.symbol, Validators.required],
          status: [this.item.status, Validators.required]
      });
      this.response = false;

      })
  };

}
