import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //apiURL: string = 'http://localhost/salesDoplus/plexapi/api/';
  apiURL: string
  imageUrl: string;
  domain = window.location.origin;
  sessions;
  constructor(private http: HttpClient, public router: Router) {
    let date = new Date();
    let currentYear = date.getFullYear();
    this.sessions = [{ id: 1, year: 2015 }, { id: 2, year: 2016 }, { id: 3, year: 2017 }, { id: 4, year: 2018 }, { id: 5, year: 2019 }, { id: 6, year: currentYear }];
    if (this.domain == 'http://localhost:4200' || this.domain == 'http://localhost:4401') {
      this.apiURL = 'https://link.dopluserp.com/api/v1/';
      this.imageUrl = 'https://link.dopluserp.com/';
    } else {
      this.apiURL = 'https://link.dopluserp.com/api/v1/';
      this.imageUrl = 'https://link.dopluserp.com/';
    }
  }
  isLogged() {
    if (localStorage.getItem('token') == null) {
      this.router.navigate(['/login']);
    }
  }
  getItem(service) {
    return this.http.get(this.apiURL + service);
  }
  //store/add data using type -------  store api url
  store(service, items) {
    return this.http.post(this.apiURL + service, items);
  }
  getReport(request) {
    return this.http.post(this.apiURL + 'report', request);
  }
  post(service, request) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }
  add(service, request) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }
  edit(service, request) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }
  delete(service, request) {
    let reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    return this.http.post(this.apiURL + service, request, { headers: reqHeader });
  }
  verify(items) {
    const headers = new HttpHeaders()
    return this.http.post(this.apiURL + 'login', items);
  }
}
