import { Component, OnInit } from '@angular/core';
import { ApiService} from '../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  pageurl=false;
  loginForm: FormGroup;
  error;
  submitted = false;
  _permissionsView: any;
  response:string;
  isSuccess = false;


  constructor(private router: Router, private api:ApiService, private formBuilder: FormBuilder, public activatedRoute: ActivatedRoute) {


   }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
  })
  }
  get f() { return this.loginForm.controls; }

  
submitItem(){
  this.isSuccess=true;

  if (this.loginForm.invalid) {
    return;
}
  this.error='';
  this.submitted = true;
  let request ={email:this.loginForm.value.email, type:"ADMIN", password:this.loginForm.value.password};

    this.api.verify(request).subscribe(
      data=>{
        if(data){
          this.isSuccess=false;
          this._permissionsView = data;
          localStorage.setItem('token', this._permissionsView.token);
          localStorage.setItem('user_id', this._permissionsView.user_id);
          this.router.navigate(['/dashboard']);
        }
      },
      err =>{
        this.isSuccess=false;
        this.error = "Server error found";
        this.response = err.error.message;
      },
      ()=>console.log('loading completed')
    )
  }


}
