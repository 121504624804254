import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { ListComponent } from './page/sms-plan/list/list.component';
import { AddComponent } from './page/sms-plan/add/add.component';
import { SmsRequestComponent } from './page/sms-request/sms-request.component';
import { SmsReportComponent } from './page/sms-report/sms-report.component';
import { SchoolAddComponent } from './page/school/school-add/school-add.component';
import { SchoolListComponent } from './page/school/school-list/school-list.component';
import { StateAddComponent } from './page/state/state-add/state-add.component';
import { StateListComponent } from './page/state/state-list/state-list.component';
import { ModuleListComponent } from './page/module/module-list/module-list.component';
import { ModuleAddComponent } from './page/module/module-add/module-add.component';
import { CountryListComponent } from './page/country/country-list/country-list.component';
import { CountryAddComponent } from './page/country/country-add/country-add.component';




const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'sms-plan', component: ListComponent },
  { path: 'sms-plan/add', component: AddComponent },
  { path: 'sms-plan/edit/:id', component: AddComponent },
  { path: 'sms-request', component: SmsRequestComponent },
  { path: 'sms-request/:add', component: SmsRequestComponent },
  { path: 'sms-report', component: SmsReportComponent },
  { path: 'school/add', component: SchoolAddComponent },
  { path: 'school/edit/:id', component: SchoolAddComponent },
  { path: 'school/:type', component: SchoolListComponent },
  { path: 'school', component: SchoolListComponent },
  { path: 'state/add', component: StateAddComponent },
  { path: 'state/list', component: StateListComponent },
  { path: 'module/add', component: ModuleAddComponent },
  { path: 'module/add/:id', component: ModuleAddComponent },
  { path: 'module/list', component: ModuleListComponent },
  { path: 'country/list', component: CountryListComponent },
  { path: 'country/add', component: CountryAddComponent },
  { path: 'country/add/:id', component: CountryAddComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
