import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../../modal/confirmation-dialog.service';


@Component({
  selector: 'app-sms-request',
  templateUrl: './sms-request.component.html',
  styleUrls: ['./sms-request.component.css']
})
export class SmsRequestComponent implements OnInit {

  type;
  items;
  schools;
  error:string='';
  response = false;
  submitted = false;
  success;
  routeType;
  request;
  plans;
  smsplan

  // current page of items
  pageOfItems: Array<any>;


  constructor(private api: ApiService, private route: ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) {

    this.request = {status:true,school_id:'',quantity:'',price:''};

   }

  ngOnInit() {
    this.getItems();
    this.api.isLogged();
    this. getSchool();
    this.routeType = this.route.snapshot.params['add'];

    if(this.routeType){
      this.getPlans();
      this. getSchool();

    }


  }

  getItems() {
    this.api.post('list', { type: 'sms-request' }).subscribe(
      data => {

        this.items = data;

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  deleteItem(id){
    this.response=true;

    this.api.delete('delete',{ id: id, type: 'sms-request' }).subscribe(
      data=>{
        this.response=false;
        this.getItems();
      },err=>{
        this.response = false;
        console.log(err);
      }
    )

  };

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  itemAction(action) {
    action.type = "sms-request-action";
    this.api.add('add', action).subscribe(item => {
      this.getItems()

    }, err => {
      this.response = false;
    })
  };


  getSchool(){
    this.api.post('list', {type:'organizations'}).subscribe(
      data=>{
        this.schools = data;
      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  getPlans(){
    this.api.post('list', { type: 'sms-list' }).subscribe(
      data=>{
        this.plans = data;
      },
      err =>{
        console.error(err);
      },
      ()=>console.log('loading completed')
    )
  };

  submitItem(sendData){

    this.success='';
    this.error='';
    this.response = true;
    this.submitted = true;
    sendData.user_id = localStorage.getItem('user_id');
    sendData.type='sms-request';
  
    this.api.add('add', sendData).subscribe(
      data=>{
          this.response =false;
          if(data=='success'){
            this.success="New item created successfully";
          }
        },
        err =>{
          this.response = false;
          this.error = "Server error found";
        }
      )
    }


    changeQuantity(school_id){
      console.log(this.smsplan.quantity);
      this.request = {price:this.smsplan.price, quantity:this.smsplan.quantity, sms_plan_id:this.smsplan.id, status:true,school_id:school_id};
    }





}
