import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { findLast } from '@angular/compiler/src/directive_resolver';

@Component({
  selector: 'app-school-add',
  templateUrl: './school-add.component.html',
  styleUrls: ['./school-add.component.css']
})
export class SchoolAddComponent implements OnInit {
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  item;
  items;
  success: string = '';
  error: string = '';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  modules;
  tempArr: any = [];
  isChecked;
  sessions;
  countries: any = [];
  states;
  filterStates = [];
  selectedFile: File;
  totalAmount: number = 0;
  modulePrice: number = 0
  totalSchool = 1;
  id: number;


  constructor(private api: ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.item = {};


  }


  ngOnInit() {
    this.getCountry();
    this.getState();
    this.sessions = this.api.sessions;
    this.id = this.route.snapshot.params['id'];
    // if(this.id){
    //   this.getItemById(this.id);
    // }
    this.item.tot_school = 1;

    this.getModules();

    this.registerForm = this.formBuilder.group({
      state: ['', Validators.required],
      country: ['', Validators.required],
      currency: ['', Validators.required],
      referral_code: [''],
      totalAmount: [''],
      user_name: ['', Validators.required],
      user_mobile: ['', Validators.required],
      user_email: ['', Validators.required],
      user_password: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required],
      tot_school: ['1', Validators.required]
    })
  }



  get f() { return this.registerForm.controls; }

  // onFileChanged(event) {
  //   this.selectedFile = event.target.files[0];
  // }

  submitItem(val) {

    if (this.registerForm.invalid) {
      return;
    }

    this.success = '';
    this.error = '';
    this.response = true;
    this.submitted = true;
    let request = this.registerForm.value;
    request.id = this.id;
    request.modules = this.tempArr;
    request.type = 'school';

    this.api.add('add', request).subscribe(
      data => {

        if (data == 'success') {
          this.response = false;
          this.tempArr = [];
          this.success = "New item created successfully";

          this.registerForm.reset();
          this.submitted = false;
          this.wizard.goToPreviousStep();
          this.ngOnInit();
        } else if (data == "duplicate") {
          this.error = "Email ID already exists";
        }

      },
      err => {
        this.response = false;
        console.log('error getting', err.error);
        this.error = "Server error found";
      },
      () => {
        this.response = false;
        console.log('loading completed')
      }
    )
  }



  getModules() {
    this.response = true;
    this.api.post('list', { type: 'modules' }).subscribe(
      data => {
        this.modules = data;
        this.id = this.route.snapshot.params['id'];
        if (this.id) {
          this.getItemById(this.id);
        }
      },
      err => {

      },
      () => {
        this.response = false;
      }
    )
  };

  amountSet() {
    var MAIN = this;
    MAIN.item.totalAmount = 0;
    this.tempArr.forEach(async (i) => {
      var check = await this.modules.find((master_m) => i.id == master_m.id);
      if (check) {
        MAIN.item.totalAmount += MAIN.totalSchool * check.price;
      }
    });
  }

  numberOfSchool(event) {
    if (event.target.value != '') {
      this.totalSchool = event.target.value;
      this.totalAmount = this.modulePrice * this.totalSchool;
    }
    else {
      this.totalAmount = this.modulePrice;
    }
    this.amountSet();
  }

  async onChangeCategory(event, module: any) { // Use appropriate model type instead of any
    if (event.target.checked == true) {
      this.tempArr.push({ title: module.title, type: module.key, id: module.id });
    } else {
      var index = await this.tempArr.findIndex(item => item.id == module.id);
      this.tempArr.splice(index, 1);
    }
    this.amountSet();
  }

  onChange(country_d) {
    let newStates = [];
    this.states.forEach(function (value) {
      if (value.country_id == country_d) {
        newStates.push(value);
      }
    });
    this.filterStates = newStates;

    this.countries.forEach(element => {
      if (element.id == country_d) {
        this.item.currency = element.currency;
      }


    });

  }

  getCountry() {
    this.api.post('list', { type: 'country' }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  getState() {
    this.api.post('list', { type: 'state' }).subscribe(
      data => {
        this.states = data;
      },
    )
  };


  getItemById(id) {
    this.response = true;
    this.api.edit('school-edit', { type: 'organization', id: this.id }).subscribe(
      async (data) => {
        this.response = false;

        this.items = data;
        this.onChange(this.items.user.school_info.country);
        this.registerForm = this.formBuilder.group({
          state: [this.items.user.school_info.state, Validators.required],
          country: [this.items.user.school_info.country, Validators.required],
          currency: [this.items.user.school_info.currency, Validators.required],
          referral_code: [this.items.user.referral_code],
          totalAmount: [this.items.user.amount],
          user_name: [this.items.user.name, Validators.required],
          user_mobile: [this.items.user.mobile, Validators.required],
          user_email: [this.items.user.email, Validators.required],
          user_password: [''],
          question: [this.items.user.question, Validators.required],
          answer: [this.items.user.answer, Validators.required],
          tot_school: [parseInt(this.items.user.school), Validators.required]
        })
        this.item.tot_school = this.items.user.school;
        this.totalSchool = this.items.user.school;
        await this.items.user.modules.forEach(async (m) => {
          var check = await this.modules.find((master_m) => m.module_id == master_m.id);
          check.checked = true;
          if (!this.tempArr.find((t) => t.id == check.id)) {
            this.tempArr.push({ title: check.title, type: check.key, id: check.id });
          }
        })

        if (this.items.user.type != "ORGANIZATION") {
          this.modules = this.modules.filter(m => this.items.admin.modules.find(am => (am.module_id == m.id)))
        }

        this.amountSet();

      },
      err => {
        console.error(err);
      }

    )
  };
}
