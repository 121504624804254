import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-module-add',
  templateUrl: './module-add.component.html',
  styleUrls: ['./module-add.component.css']
})
export class ModuleAddComponent implements OnInit {
  id:number;
  item;
  success:string='';
  error:string='';
  response = false;
  registerForm: FormGroup;
  submitted = false;
  constructor(private api:ApiService, private formBuilder: FormBuilder, private route:ActivatedRoute) {
   }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      type: ['']
  })

  this.id = this.route.snapshot.params['id'];
  if(this.id){
    this.getItemById(this.id);
  }

  }

  get f() { return this.registerForm.controls; }



submitItem(val){

  if (this.registerForm.invalid) {
    return;
}

  this.success='';
  this.error='';
  this.response = true;
  this.submitted = true;
  let request = this.registerForm.value;
  request.type= 'module';
  request.id= this.id;

    this.api.add('add', request).subscribe(
      data=>{
        this.response =false;
        if(data=='success'){
          this.success=this.id ? 'Updated successfully' : "New item created successfully";
        }

      },
      err =>{
        this.response = false;
        console.log('error getting',err.error);
        this.error = "Server error found";
      },
      ()=>console.log('loading completed')
    )
  }

  
  getItemById(id){

    this.api.edit('edit', {id:id, type:"module"}).subscribe(
      data=>{
        this.item = data;
        this.registerForm = this.formBuilder.group({
          name: [this.item.title, Validators.required],
          price: [this.item.price, Validators.required],
          type: [this.item.type],
      });

      })
  };

}
