import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTableModule } from 'ng-angular8-datatable';
import { ArchwizardModule } from 'angular-archwizard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { ConfirmationDialogService } from './modal/confirmation-dialog.service';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './page/login/login.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { HeaderComponent } from './page/header/header.component';
import { ListComponent } from './page/sms-plan/list/list.component';
import { AddComponent } from './page/sms-plan/add/add.component';
import { SmsRequestComponent } from './page/sms-request/sms-request.component';
import { SmsReportComponent } from './page/sms-report/sms-report.component';
import { SchoolListComponent } from './page/school/school-list/school-list.component';
import { SchoolAddComponent } from './page/school/school-add/school-add.component';
import { StateAddComponent } from './page/state/state-add/state-add.component';
import { StateListComponent } from './page/state/state-list/state-list.component';
import { ModuleListComponent } from './page/module/module-list/module-list.component';
import { ModuleAddComponent } from './page/module/module-add/module-add.component';
import { ConfirmationDialogComponent } from './modal/confirmation-dialog/confirmation-dialog.component';
import { CountryListComponent } from './page/country/country-list/country-list.component';
import { CountryAddComponent } from './page/country/country-add/country-add.component';
import { SearchFilterPipe } from './search-filter.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    ListComponent,
    AddComponent,
    SmsRequestComponent,
    SmsReportComponent,
    SchoolAddComponent,
    SchoolListComponent,
    StateAddComponent,
    StateListComponent,
    ModuleListComponent,
    ModuleAddComponent,
    JwPaginationComponent,
    ConfirmationDialogComponent,
    CountryListComponent,
    CountryAddComponent,
    SearchFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTableModule,
    ArchwizardModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule

  ],
  providers: [ConfirmationDialogService],
  bootstrap: [AppComponent],
  entryComponents: [ ConfirmationDialogComponent ]
})
export class AppModule { }
