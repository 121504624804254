import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  id:number;
  items;
  success:string='';
  error:string='';
  response = false;
  submitted = false;
  registerForm: FormGroup;
  
  

  constructor(private api:ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
   }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if(this.id){
      this.getItemById(this.id);
      console.log("id=",this.id);

    }

    this.registerForm = this.formBuilder.group({
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      status: ['1'],
  
    })

  }


  getItemById(id) {
    this.api.edit('edit', { id: id, type: "sms-list" }).subscribe(
      data => {
        this.items = data;

        this.registerForm = this.formBuilder.group({
          quantity: [this.items.quantity, Validators.required],
          price: [this.items.price, Validators.required],
          status: [this.items.status, Validators.required]
      });
      this.response = false;
   
      },
      err => {
        console.error(err);
      },
    )
  };



  get f() { return this.registerForm.controls; }

  submitItem(){

    if (this.registerForm.invalid) {
      return;
    }
    
  
    this.response = true;
    this.submitted = true;
    let request = this.registerForm.value;
    request.type = 'sms-plan';
    request.id = this.id;
  
    this.api.add('add', request).subscribe(
      data=>{
        this.response = false;
            this.success= this.id ? "Item updated successfully" : "New item created successfully";
        },
        err =>{
          this.response = false;
        }
      )
    }

}
