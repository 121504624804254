import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../../../modal/confirmation-dialog.service';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.css']
})
export class CountryListComponent implements OnInit {

  type;
  items;
  error:string='';
  response = false;
  success;
  smsplan={price:0};
  plans=[];
  schools=[];
  routeType;
  request;
  pageOfItems: Array<any>;


  constructor(private api: ApiService, private route: ActivatedRoute,private confirmationDialogService: ConfirmationDialogService) {
    this.request = {status:true,school_id:'',quantity:'',price:''};

  }
  ngOnInit() {
    this.getItems();
    this.api.isLogged();
  }


  submitItem(form) {
  }
  
  changeQuantity(form) {
  }
  
  getItems() {
    this.response = true;

    this.api.post('list', { type: 'country' }).subscribe(
      data => {
        this.items = data;
        this.response = false;

      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

  deleteItem(id) {
    this.response = true;

    this.api.delete('delete',{ id: id, type: 'country' }).subscribe(
      data => {
        this.response = false;
        this.getItems();
      }, err => {
        this.response = false;
        console.log(err);
      }
    )

  }

  
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete... ?')
    .then((confirmed) => {console.log('User confirmed:', confirmed)
    if(confirmed){
      this.deleteItem(id);

    }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
