import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showHead: any;

  constructor(public router:Router) { }

  ngOnInit() {
  }

  Logout(){
    localStorage.clear();
    localStorage.removeItem('token')
    this.router.navigate(['/login']);
    this.showHead = false;
  }

}
