import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../..///api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-state-add',
  templateUrl: './state-add.component.html',
  styleUrls: ['./state-add.component.css']
})
export class StateAddComponent implements OnInit {

  id: number;
  items = {};
  responseSuccess: string = '';
  responseError: string = '';
  response = false;
  status;
  countries;
  registerForm: FormGroup;
  submitted = false;
  constructor(private api: ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getItemById(this.id);
    }
    this.getCountry();

    this.registerForm = this.formBuilder.group({
      country: ['', Validators.required],
      title: ['', Validators.required],
      status: ['1', Validators.required],
  
    })

  }

  getCountry() {
    this.api.post('list', { type: 'country' }).subscribe(
      data => {
        this.countries = data;
      },
      err => {
        console.error(err);
      },
    )
  };


  getItemById(id) {
    this.api.edit('edit', { id: id, type: "state" }).subscribe(
      data => {
        this.items = data;
        if (this.items) {
          this.status = true;
        }
      },
      err => {
        console.error(err);
      },
      () => console.log('loading completed')
    )
  };

      



get f() { return this.registerForm.controls; }


submitItem(){


  if (this.registerForm.invalid) {
    return;
  }

  this.response = true;
  this.submitted = true;
  let request = this.registerForm.value;
  request.type = 'state';

  this.api.add('add', request).subscribe(
    data => {
      this.response = false;
      if (data) {
        this.responseSuccess = this.id ? "Item updated successfully" : "New item created successfully";
        if(!this.id){
          this.registerForm.reset();
          this.submitted = false;
          this.ngOnInit();
        }
      }

    },
    err => {
      this.response = false;
    },
  )
}



}
